<template>
  <v-app id="register">
    <header-register :step="2" />
    <v-container fluid>
      <div class="main-container">
        <div class="pb-6 mt-sm-n12" style="width: 80vh">
          <v-row>
            <v-col class="txt-sub-6" cols="12"> Password Akun </v-col>
          </v-row>

          <v-row>
            <v-col class="txt-sub-1"> Silahkan isi password Anda </v-col>
          </v-row>

          <v-row>
            <v-col class="txt-sub-1" cols="12" sm="4"> Email Terdaftar </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                v-model="email"
                name="email"
                type="text"
                disabled
                outlined
                autocomplete="off"
                dense
              />
            </v-col>
          </v-row>
          
          <v-row class="mt-n4">
            <v-col class="txt-sub-1" cols="12" sm="4"> HP Terdaftar </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                v-model="phone"
                name="phone"
                type="text"
                disabled
                outlined
                autocomplete="off"
                dense
              />
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col class="txt-sub-1" cols="12" sm="4">
              Password <span class="symbol-required">*</span>
            </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                v-model="password"
                name="password"
                placeholder="Password"
                outlined
                autocomplete="off"
                color="#36AC87"
                dense
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (showPassword = !showPassword)"
              />
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col class="txt-sub-1" cols="12" sm="4">
              Ulangi Password <span class="symbol-required">*</span>
            </v-col>

            <v-col cols="12" sm="8">
              <v-text-field
                v-model="konfirmasiPassword"
                name="konfirmasi-password"
                placeholder="Konfirmasi Password"
                outlined
                autocomplete="off"
                color="#36AC87"
                dense
                :type="showPassword2 ? 'text' : 'password'"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (showPassword2 = !showPassword2)"
              />
            </v-col>
          </v-row>

          <!-- Alert -->
          <v-row class="mb-n8">
            <v-col>
              <v-alert v-model="showAlert" type="error" dismissible>
                {{ alertText }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                class="text-capitalize"
                color="#36AC87"
                block
                x-large
                :disabled="disableButton"
                small
                @click="submit"
              >
                <span class="txt-btn-login"> Selesaikan Pendaftaran </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import RegistrasiHeader from "../registrasi/RegistrasiHeader.vue";
// import { mapState } from 'vuex'
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
// axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

export default {
  components: {
    "header-register": RegistrasiHeader,
  },

  data: () => ({
    // Form Data:
    email: "",
    phone: "",
    password: "",
    konfirmasiPassword: "",
    disableButton: false,
    showPassword: false,
    showPassword2: false,
    // Alert:
    showAlert: false,
    alertText: "Error",
  }),

  computed: {},

  watch: {},

  created() {
    this.email = JSON.parse(localStorage.getItem("tmp-2")).reg_email;
    this.phone = JSON.parse(localStorage.getItem("tmp-2")).reg_phone;
  },

  methods: {
    submit() {
      const requestBody = {
        reg_hash: localStorage.getItem("step-1"),
        password: this.password,
        password_confirm: this.konfirmasiPassword,
      };

      axios
        .post("/v1/auth/register/step-4", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            localStorage.clear();
            this.$router.push({ name: "Pembayaran" });
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.setAlert(Object.values(e.response.data.error)[0]);
          } else {
            this.setAlert(e.response.data.error);
          }
        });
    },

    setAlert(txt) {
      this.showAlert = true;
      this.alertText = txt;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-login {
  @extend .h-4;
  color: $gray-50;
  font-weight: bold;
}

.txt-sub-1 {
  @extend .p-1;
  color: $gray-50;
}

.txt-sub-2 {
  @extend .p-1;
  color: $gray-50;
  font-style: italic;
}

.txt-sub-3 {
  @extend .p-2;
  color: $gray-50;
}

.txt-sub-4 {
  @extend .p-2;
  color: $blue-1;
  cursor: pointer;
  font-weight: bold;
}

.txt-sub-5 {
  @extend .p-2;
  color: $gray-80;
}

.txt-sub-6 {
  @extend .h-5;
  color: $black-1;
  font-weight: bold;
}

.txt-btn-login {
  @extend .h-5;
  color: #ffffff;
  font-weight: bold;
}

.btn-login {
  color: $green-2;
}

.symbol-required {
  color: $red-1;
}

.txt-12-green-2-bold {
  @extend .p-2;
  color: $green-2;
  font-weight: bold;
}
</style>
